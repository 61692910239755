var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"friends"}},[_c('header-page',{attrs:{"photos":_vm.page.acf.header_images}}),_vm._v(" "),_c('section',{staticClass:"py-16 pb-8"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full px-4"},[_c('header-h2',{attrs:{"content":_vm.page.title,"color":"blue-dark","casing":"uppercase","family":"header","align":"center"}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.page.content)}})],1)])])]),_vm._v(" "),_c('section',{staticClass:"py-8"},[_c('div',{staticClass:"container"},[_c('client-only',[_c('masonry',{staticClass:"laptop:-ml-4 flex flex-wrap px-4 tablet:px-0",attrs:{"cols":{ default: 4, 768: 1 },"gutter":0}},_vm._l((_vm.posts),function(post,index){return _c('div',{key:index,staticClass:"w-full mb-8 px-4"},[_c('p',[_c('strong',{domProps:{"innerHTML":_vm._s(post.title)}})])])}),0)],1)],1)]),_vm._v(" "),_c('section',{staticClass:"bg-black py-16"},[_c('div',{staticClass:"container"},[_c('header-h2',{attrs:{"content":"Dex is mijn vriend!","color":"white","casing":"uppercase","family":"header","align":"center"}}),_vm._v(" "),_c('div',{staticClass:"form px-8 tablet:px-32 desktop:px-48 text-center"},[(_vm.paymentReceived)?[_c('span',{staticClass:"text-white block mt-8",attrs:{"id":"paymentReceivedMessage"}},[_vm._v("\n                        We hebben je betaling ontvangen, dankjewel!\n                    ")])]:[_c('form-main',{attrs:{"form":{
                      showLabels: false,
                      showPlaceholders: true,
                      requiredText: '*',
                      buttonType: 'primary',
                      buttonText: 'Ik draag 40 euro bij!',
                      fields: [
                        {
                          type: 'input',
                          subtype: 'text',
                          name: 'name',
                          label: 'Naam',
                          required: true
                        },
                        {
                          type: 'input',
                          subtype: 'email',
                          name: 'email',
                          label: 'E-mail',
                          required: true
                        }
                      ]
                    }}})]],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }